import { zgyxRequest, request } from '@src/utils';
const baseUrlFix = '/cmsapi';

/**
 * 项目列表
 * @param {*} data
 * @returns
 */
const apiGetProject = (params = {}) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/selection/project/quota/search', params });
};

/**
 * 项目详情
 * @param {*} projectId
 * @returns
 */
const apiGetProjectDetail = (projectId) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + `/selection/project/info/complete-result/self-evaluate/${projectId}`
  });
};

/**
 * 项目详情-未在时间中
 * @param {*} projectId
 * @returns
 */
const apiGetProjectDetailOutTime = (projectId) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + `/selection/project/info/complete-result/view/${projectId}`
  });
};

/**
 * 项目结果详情-查看
 * @param {*} projectResultId
 * @returns
 */
const apiGetProjectDetailByLook = (projectResultId) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + `/selection/project-result/info/complete-result/view/${projectResultId}`
  });
};

/**
 * 申请表详情
 * @param {*} projectResultId
 * @returns
 */
const apiGetRequisitionInfo = (projectResultId) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + `/selection/requisition/info/${projectResultId}`
  });
};

/**
 * 撤回-暂存
 * @param {*} data
 * @returns
 */
// const apiPostSelfEvaluate = (data) => {
//   return zgyxRequest({
//     method: 'post',
//     url: baseUrlFix + '/selection/project-result/declare/temporary',
//     data
//   });
// };

/**
 * 自评-暂存
 * @param {*} data
 * @returns
 */
const apiPostTemporary = (data) => {
  return zgyxRequest({
    method: 'post',
    url: baseUrlFix + '/selection/project-result/community/declare/temporary',
    data
  });
};
/**
 * 社区自评 -- 提交（无论模式）//不用了
 * @param {*}
 * @returns
 */
const apiPostSave = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/project-result/community/submit-declare', data });
};

/**
 * 社区自评 --完成协作(协作模式下的假提交)（和保存同一个格式） + 非协作模式下也可以用
 * @param {*} data
 * @returns
 */
const apiPostDeclareCompleteClaimContent = (data) => {
  return zgyxRequest({
    method: 'post',
    url: baseUrlFix + '/selection/project-result/community/declare/complete-claim-content',
    data
  });
};

/**
 * 社区自评 --完成了提交
 * @param {*} data
 * @returns
 */
const apiPostSelectionProjectResultCommunitySubmitDeclareToCounty = (data) => {
  return zgyxRequest({
    method: 'post',
    url: baseUrlFix + '/selection/project-result/community/submit-declare-to-county',
    data
  });
};

/**
 * 驳回 --提交 //不用了
 * @param {*} data
 * @returns
 */
const apiPostSaveRevise = (data) => {
  return zgyxRequest({
    method: 'post',
    url: baseUrlFix + '/selection/project-result/community/submit-amend',
    data
  });
};

/**
 * 驳回--暂存
 * @param {*} data
 * @returns
 */
const apiPostSaveRevisezc = (data) => {
  return zgyxRequest({
    method: 'post',
    url: baseUrlFix + '/selection/project-result/community/amend/temporary',
    data
  });
};

/**
 * 驳回--完成协作(协作模式下的假提交)（和保存同一个格式） + 非协作模式下也可以用
 * @param {*} data
 * @returns
 */
const apiPostAmendCompleteClaimContent = (data) => {
  return zgyxRequest({
    method: 'post',
    url: baseUrlFix + '/selection/project-result/community/amend/complete-claim-content',
    data
  });
};

/**
 * 驳回 --完成了提交
 * @param {*} data
 * @returns
 */
const apiPostSelectionProjectResultCommunitySubmitAmendToCounty = (data) => {
  return zgyxRequest({
    method: 'post',
    url: baseUrlFix + '/selection/project-result/community/submit-amend-to-county',
    data
  });
};

/**
 * 保存申请表
 * @param {*} data
 * @returns
 */
const apiPostSaveForm = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/requisition/save', data });
};

/**
 * 是否有暂存自评结果
 * @param {*} data
 * @returns
 */
// const apiGetHasTemporary = (params = {}) => {
//   return zgyxRequest({
//     method: 'get',
//     url: baseUrlFix + '/selection/project-result/community/declare/is-has-temporary',
//     params
//   });
// };

/**
 * 审核区到社区
 * @param {* projectResultId,auditingStatus, remark, realmNo} data
 * @returns
 */
const apiPostCountyAuditingByRealm = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/project-result/county/auditing/by-realm', data });
};

//2023.03.13新增

/**
 * 启用禁用
 * @param {*} data
 * @returns
 */
const apiPostAccountChangeSubStatus = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/sub/changeStatus', data });
};

/**
 * 创建子账户
 * @param {*} data
 * @returns
 */
const apiPostCmsSysUserSaveSub = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/sub/create', data });
};

/**
 * 编辑-子账户
 * @param {*} data
 * @returns
 */
const apiPostCmsSysUserUpdateSub = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/sub/update', data });
};

/**
 * 重置密码-子账户
 * @param {*} data
 * @returns
 */
const apiPostAccountResetSubPassword = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/sub/resetPassword', data });
};

/**
 * 重置密码-子账户-一键重置
 * @param {*} data
 * @returns
 */
const apiPostAccountResetDefaultSubPassword = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/system/account/sub/reset-default-password', data });
};

/**
 * 改变项目操作模式，单人或者协作模式
 * @param {*} data
 * @returns
 */
const changeCommunityOperateType = (data) => {
  return zgyxRequest({
    method: 'post',
    url: baseUrlFix + '/selection/project/synergy/change/community-operate-type',
    data
  });
};

/**
 * 获取协同模式开启状态下的 认领列表
 * @param {processStatus,projectId} data
 * @returns
 */
const getClaimList = (params = {}) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/selection/project/synergy/claim/list', params });
};

/**
 * 协作模式-认领(领域)
 * @param {realmId,processStatus} data
 * @returns
 */
const claimRealmId = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/project/synergy/community/claim', data });
};

/**
 * 协作模式-取消认领(领域)
 * @param {realmId,processStatus} data
 * @returns
 */
const disclaimRealmId = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/project/synergy/community/disclaim', data });
};

/**
 * 协作模式-主账号整体提交
 * @param {projectResultId} data
 * @returns
 */
const communityTotalSelfEvaluateSubmit = (data) => {
  return zgyxRequest({
    method: 'post',
    url: baseUrlFix + '/selection/project-result/community/total-self-evaluate/submit'
  });
};
//台账

/**
 * 台账列表
 * @param {*} data
 * @returns
 */
const taskSearch = (params = {}) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/selection/task/search', params });
};

/**
 * 台账详情
 * @param {*} id
 * @returns
 */
const taskInfo = (id) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + `/selection/task/info/${id}`
  });
};

/**
 * 台账生成
 * @param {initParam,groupName,type} data
 * @returns
 */
const taskSave = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/task/save', data });
};

/**
 * 台账取消
 * @param {id,remark} data
 * @returns
 */
const taskCancel = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/task/cancel', data });
};

/**
 * 下载台账
 * @param {*} data
 * @returns
 */
// const taskDownLoad = (params = {}) => {
//   return request({
//     method: 'get',
//     url: `${baseUrlFix}/system/upload/preview`,
//     params,
//     responseType: 'blob'
//   });
// };

const taskDownLoad = (params) => {
  return zgyxRequest({ method: 'get', url: baseUrlFix + '/system/upload/preview', params });
};

/**
 * 撤回
 * @param {*} data
 * @returns
 */
const apiPostWithdraw = (data) => {
  return zgyxRequest({
    method: 'post',
    url: baseUrlFix + '/selection/project-result/community/withdraw',
    data
  });
};

export {
  apiGetProject,
  apiGetProjectDetail,
  apiGetProjectDetailByLook,
  apiGetProjectDetailOutTime,
  apiPostTemporary,
  apiPostSave,
  apiPostSaveForm,
  apiGetRequisitionInfo,
  // apiGetHasTemporary,
  apiPostCountyAuditingByRealm,
  apiPostSaveRevise,
  apiPostAccountChangeSubStatus,
  apiPostCmsSysUserSaveSub,
  apiPostAccountResetSubPassword,
  apiPostCmsSysUserUpdateSub,
  apiPostAccountResetDefaultSubPassword,
  changeCommunityOperateType,
  getClaimList,
  claimRealmId,
  disclaimRealmId,
  communityTotalSelfEvaluateSubmit,
  taskSearch,
  taskInfo,
  taskSave,
  taskCancel,
  taskDownLoad,
  apiPostWithdraw,
  // apiPostSelfEvaluate,
  apiPostSaveRevisezc,
  apiPostAmendCompleteClaimContent,
  apiPostDeclareCompleteClaimContent,
  apiPostSelectionProjectResultCommunitySubmitDeclareToCounty,
  apiPostSelectionProjectResultCommunitySubmitAmendToCounty
};
