const checkPassword = (rule, value, callback) => {
  const reg =
    /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,}$/;
  if (!value) {
    return Promise.reject('请输入密码');
  } else if (!reg.test(value)) {
    return Promise.reject('请输入至少8位并同时包含大写字母、小写字母、数字、符号中的其中三种的密码');
  } else {
    return Promise.resolve();
  }
};
const checkPhone = (rule, value, callback) => {
  const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
  if (!value) {
    return Promise.reject('请输入绑定手机号码');
  } else if (!reg.test(value)) {
    return Promise.reject('请输入正确格式的手机号码');
  } else {
    return Promise.resolve();
  }
};
const checkAccount = (rule, value, callback) => {
  const reg = /^[a-zA-Z0-9]{4,20}$/;
  if (!value) {
    return Promise.reject('请输入用户名');
  } else if (!reg.test(value)) {
    return Promise.reject('请输入4-20个字母或数字的用户名');
  } else {
    return Promise.resolve();
  }
};
const rules = {
  username: [{ required: true, message: '请输入子账户名' }],
  nickName: [{ required: true, message: '请输入姓名' }],
  mobile: [{ required: true, validator: checkPhone }],
  password: [{ required: true, validator: checkPassword }],
  passwordConfirm: [{ required: true, validator: checkPassword }]
};

const rulesForPassword = {
  password: [{ required: true, validator: checkPassword }],
  passwordConfirm: [{ required: true, validator: checkPassword }]
};
export { rules, rulesForPassword };
