import Hashes from 'jshashes';
/**
 * 生成加密的密码
 * @param {*} publicKey
 * @param {*} password
 * @returns
 */
const SHA1 = new Hashes.SHA1();
const createJSEncryptPassword = (password) => {
  try {
    return SHA1.hex(password);
  } catch (error) {
    console.error(error);
  }
};
export { createJSEncryptPassword };
